:root {
    --bs-body-font-family: Inter Tight;
    --bs-body-color: #101820;
    --text-secondary: #6B6B6B;
    --primary: #97069E;
    --surface: #F8F8F8;

    .accordion {
        --bs-accordion-btn-icon: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.7"><path d="M8.00001 11.2C7.53335 11.2 7.06668 11.02 6.71335 10.6667L2.36668 6.31999C2.17335 6.12666 2.17335 5.80666 2.36668 5.61332C2.56001 5.41999 2.88001 5.41999 3.07335 5.61332L7.42001 9.95999C7.74001 10.28 8.26001 10.28 8.58001 9.95999L12.9267 5.61332C13.12 5.41999 13.44 5.41999 13.6333 5.61332C13.8267 5.80666 13.8267 6.12666 13.6333 6.31999L9.28668 10.6667C8.93335 11.02 8.46668 11.2 8.00001 11.2Z" fill="%23070A14"/></g></svg>');
        --bs-accordion-btn-active-icon: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.7"><path d="M8.00001 11.2C7.53335 11.2 7.06668 11.02 6.71335 10.6667L2.36668 6.31999C2.17335 6.12666 2.17335 5.80666 2.36668 5.61332C2.56001 5.41999 2.88001 5.41999 3.07335 5.61332L7.42001 9.95999C7.74001 10.28 8.26001 10.28 8.58001 9.95999L12.9267 5.61332C13.12 5.41999 13.44 5.41999 13.6333 5.61332C13.8267 5.80666 13.8267 6.12666 13.6333 6.31999L9.28668 10.6667C8.93335 11.02 8.46668 11.2 8.00001 11.2Z" fill="%23070A14"/></g></svg>');
    }
}

a,
a:hover {
    color: var(--bs-body-color);
    text-decoration: none;
}

.text-primary {
    color: var(--bs-body-color) !important;
}

.text-secondary {
    color: var(--text-secondary) !important;
}

.bg-surface {
    background-color: var(--surface) !important;
}

.btn {
    border-radius: 100rem;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    padding: 14px 25.5px;
}

.btn.btn-small {
    padding: 10px 20px;
}

.btn-primary {
    --bs-btn-bg: var(--primary);
    --bs-btn-border-color: var(--primary);
    --bs-btn-hover-bg: var(--primary);
    --bs-btn-hover-border-color: var(--primary);
    --bs-btn-active-bg: var(--primary);
    --bs-btn-active-border-color: var(--primary);
    --bs-btn-disabled-bg: var(--primary);
    --bs-btn-disabled-border-color: var(--primary);
}

.btn-outline-primary {
    --bs-btn-color: var(--primary);
    --bs-btn-border-color: var(--primary);
    --bs-btn-hover-bg: var(--primary);
    --bs-btn-hover-border-color: var(--primary);
    --bs-btn-active-bg: var(--primary);
    --bs-btn-active-border-color: var(--primary);
    --bs-btn-disabled-color: var(--primary);
    --bs-btn-disabled-border-color: var(--primary);
}

.form-check-input {
    background-size: 8px;
}

.form-check-input[type=checkbox] {
    border-radius: 2.5px;
}
.form-check-input:checked {
    background-color: #4361EE;
    border-color: #4361EE;
}

.form-check-input:checked[type=checkbox]{
    --bs-form-check-bg-image: url('data:image/svg+xml,<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.81621 5.98329C3.64954 5.98329 3.49121 5.91663 3.37454 5.79996L1.01621 3.44163C0.774544 3.19996 0.774544 2.79996 1.01621 2.55829C1.25788 2.31663 1.65788 2.31663 1.89954 2.55829L3.81621 4.47496L8.09954 0.191626C8.34121 -0.0500407 8.74121 -0.0500407 8.98288 0.191626C9.22454 0.433293 9.22454 0.833292 8.98288 1.07496L4.25788 5.79996C4.14121 5.91663 3.98288 5.98329 3.81621 5.98329Z" fill="white"/></svg>');
}

.form-label {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
}

.form-control,
.form-select {
    font-size: 14px;
    line-height: 20px;
    padding: 16px 12px;
    border-radius: 8px;
}
.form-control-icon {
    position: relative;
}

.form-control-icon svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
}

.form-control-icon .form-control {
    padding-right: 44px;
}

.search-box .search-icon {
    left: 16px;
}

.search-box input {
    padding-left: 44px !important;
}

/* Header */
.header .navbar-toggler:focus {
    box-shadow: none;
}

/* Header */

/* Tutor Page */
.tutor-banner-slider .swiper,
.tutor-banner-slider .slider-box {
    border-radius: 20px;
}

.tutor-banner-slider .slider-box {
    height: 488px;
}

.tutor-banner-slider .slider-box::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 6%, rgba(0, 0, 0, 0.8) 100%);
}

.tutor-banner-slider .slider-box .title {
    font-size: 48px;
    line-height: 58px;
}

.tutor-banner-slider .slider-box .subtitle {
    font-size: 20px;
    line-height: 24px;
}

.tutor-banner-slider .swiper-pagination.swiper-pagination-bullets {
    bottom: 40px;
}

.tutor-banner-slider .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    width: 140px;
    height: 4px;
    border-radius: 100rem;
    margin: 0 11px;
    background: rgba(255, 255, 255, 0.7);
    opacity: 1;
}

.tutor-banner-slider .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--primary);
}

.tutor-title {
    font-size: 32px;
    line-height: 38px;
}

.tutor-title .mobile-setting {
    width: 40px;
    height: 40px;
    min-width: 40px;
}

.tutor-wrapper {
    gap: 20px;
}

.tutor-card-filter {
    width: 100%;
    max-width: 305px;
}

.tutor-card-filter .title {
    font-size: 18px;
    line-height: 22px;
}

.tutor-card-filter .selected-filter {
    gap: 8px;
}

.tutor-card-filter .selected-filter li,
.location-text {
    padding: 5px 12px;
    border: 1px solid #1018201A;
    font-size: 12px;
    line-height: 15px;
}

.tutor-card-filter .selected-filter li svg,
.location-text svg {
    min-width: fit-content;
}

.tutor-card-filter .selected-filter li span,
.location-text span {
    max-width: 211px;
}

.tutor-card-filter .tutor-search {
    padding: 24px 0;
    border-bottom: 1px solid #1018201A;
    border-top: 1px solid #1018201A;
}

.tutor-card-filter .tutor-search input {
    padding-left: 44px !important;
}

.tutor-card-filter .tutor-search .search-icon {
    left: 16px;
}

.tutor-card-filter .tutor-filter .accordion-item {
    border: none;
    border-bottom: 1px solid #1018201A;
}

.tutor-card-filter .tutor-filter .accordion-item .accordion-button {
    padding: 24px 0;
    border-radius: 0;
    font-size: 18px;
    line-height: 22px;
}

.tutor-card-filter .tutor-filter .accordion-item .accordion-button:is(:not(.collapsed), :focus) {
    background-color: transparent;
    box-shadow: none;
    color: var(--bs-body-color);
}

.tutor-card-filter .tutor-filter .accordion-item .accordion-body {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 24px;
}

.tutor-card-filter .tutor-filter .accordion-item .accordion-body .badge {
    background-color: #EBEFF5;
}

.tutor-card-filter .tutor-filter .accordion-item .accordion-body ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.tutor-card-filter .tutor-filter .accordion-item .accordion-body .show-more-btn {
    font-size: 14px;
    line-height: 17px;
}

.tutor-card-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.tutor-card {
    --bs-border-color: rgba(16, 24, 32, 0.1);
}

.tutor-card .img-box img {
    max-width: 303px;
    max-height: 189px;
}

.tutor-card .img-box:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 1) 100%);
    opacity: 0.2;
}

.tutor-card .content-box .title {
    font-size: 20px;
    line-height: 24px;
    gap: 8px;
}

.tutor-card .content-box .rating-review,
.tutor-card .content-box .location {
    font-size: 13px;
    line-height: 16px;
}

.tutor-card .content-box .bottom-col .label {
    opacity: 0.7;
    font-size: 12px;
    line-height: 15px;
}

.tutor-card .content-box .bottom-col .value {
    font-size: 14px;
    line-height: 17px;
}

/* Tutor Page */

/* breadcrumb */
.breadcrumb {
    font-size: 12px;
    line-height: 15px;
}

/* breadcrumb */

/* tutor-profile-page */
.tutor-profile {
    --bs-border-color: #1018201A;
    border-radius: 12px;
    margin-bottom: 40px;
}

.tutor-profile .tutor-profile-top {
    padding: 20px 20px 30px;
}

.tutor-profile .tutor-profile-top .tutor-img {
    aspect-ratio: 1/1;
}

.tutor-profile .tutor-profile-top .title {
    font-size: 32px;
    line-height: 38px;
}

.tutor-profile .tutor-profile-top .subtitle {
    font-size: 20px;
    line-height: 24px;
}

.tutor-profile .tutor-profile-top .rating-review,
.tutor-profile .tutor-profile-top .location {
    font-size: 15px;
    line-height: 18px;
}

.tutor-profile .tutor-profile-top .rate {
    font-size: 40px;
    line-height: 48px;
}

.tutor-profile .tutor-profile-bottom {
    padding: 25px 20px;
    gap: 60px;
}

.tutor-profile .tutor-profile-bottom .divider {
    background-color: #1018201A;
    width: 1px;
    height: 43px;
}

.tutor-profile .tutor-profile-bottom .label {
    font-size: 14px;
    line-height: 17px;
}

.tutor-profile .tutor-profile-bottom .value {
    font-size: 18px;
    line-height: 22px;
}

.tutor-info {
    margin-bottom: 50px;
}

.tutor-info .nav-tabs {
    column-gap: 24px;
}

.tutor-info .nav-tabs .nav-link {
    padding: 0;
    padding-bottom: 12px;
    color: var(--text-secondary);
    border: none;
    font-weight: 500;
}

.tutor-info .nav-tabs .nav-link.active {
    border: none;
    border-bottom: 2px solid;
    color: var(--bs-body-color);
    font-weight: 600;
}

.tutor-info .intro-box .title {
    font-size: 32px;
    line-height: 38px;
}

.tutor-info .intro-box .content {
    font-size: 19px;
    line-height: 38px;
    opacity: 0.7;
}

.tutor-info .intro-box .intro-video {
    border-radius: 15px;
    overflow: hidden;
}

.tutor-info .intro-box .intro-video:not(.playing):after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 6%, rgba(0, 0, 0, 0.8) 100%);
}

.tutor-info .review-box .review-col {
    gap: 32px;
}

.tutor-info .review-box .review-item {
    gap: 12px;
    width: 100%;
    max-width: 450px;
}

.tutor-info .review-box .review-item .user-avatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    background-color: #222222;
    color: #D9D9D9;
    font-size: 14px;
    line-height: 17px;
}

.tutor-info .review-box .review-item .name {
    margin-bottom: 6px;
}

.tutor-info .review-box .review-item .name h4 {
    font-size: 20px;
    line-height: 24px;
}

.tutor-info .review-box .review-item .name small,
.tutor-info .review-box .review-item .review {
    font-size: 10px;
    line-height: 12px;
    color: #5D4F52;
}

/* tutor-profile-page */



/* location-modal */

.location-modal .location-search {

    padding: 20px 32px 12px;

}



/* location-modal */



/* create-tutor-modal */

.create-tutor-modal {

    --bs-modal-margin: 0;

}



.create-tutor-modal .modal-dialog {

    margin-right: 0;

}



.create-tutor-modal .modal-dialog .modal-content {

    height: 100vh;

    overflow: auto;

    border-top-right-radius: 0;

    border-bottom-right-radius: 0;

}



.create-tutor-modal.modal.fade .modal-dialog {

    transform: translate(50px, 0);

}



.create-tutor-modal.modal.fade.show .modal-dialog {

    transform: none;

}



/* create-tutor-modal */

/* autocomplete gmap */

.pac-container {
    z-index: 9999;
}
.pac-container .pac-item {
    padding: 10px;
}

/* autocomplete gmap end */

.ant-picker-dropdown {
    z-index: 9999 !important; /* Ensure it's above other elements */
}