@media (min-width: 768px) {
    .modal-dialog {
        --bs-modal-width: 760px;
        --bs-modal-border-radius: 20px;
        --bs-modal-header-padding: 32px 32px 25px;
        --bs-modal-header-border-color: #1018201a;
        --bs-modal-padding: 32px;
    }
}

@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1304px;
    }
}

@media (max-width: 1199.98px) {
    .tutor-card-filter {
        max-width: 200px;
    }

    .tutor-profile .tutor-profile-bottom {
        gap: 20px;
    }
}

@media (max-width: 991.98px) {
    .btn {
        padding: 10px 25.5px;
    }

    .header .navbar-collapse {
        border-bottom: 1px solid #1018201A;
    }

    .header .navbar {
        padding: 15px 0 !important;
    }

    .header .navbar .navbar-brand img {
        max-width: 150px;
    }

    .header .navbar-nav .nav-item .nav-link {
        padding: 15px 0 0 !important;
    }

    .header .navbar-nav .nav-button {
        display: flex;
        gap: 15px;
        margin-top: 15px;
    }

    .header .navbar-nav .nav-button button {
        margin-left: 0 !important;
    }

    .tutor-banner-slider .slider-box .title {
        font-size: 24px;
        line-height: 1.5;
        margin-bottom: 0 !important;
    }

    .tutor-banner-slider .slider-box .subtitle {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400 !important;
    }

    .tutor-banner-slider .swiper-pagination.swiper-pagination-bullets {
        bottom: 5px;
    }

    .tutor-banner-slider .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
        width: 40px;
        height: 4px;
        border-radius: 100rem;
        margin: 0 11px;
        background: rgba(255, 255, 255, 0.7);
        opacity: 1;
    }

    .tutor-card-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }

    .tutor-profile-bottom {
        flex-wrap: wrap;
    }

    .tutor-profile-bottom>div {
        flex: 1 0 calc(50% - 10px);
    }

    .tutor-profile .tutor-profile-bottom .divider {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .header .navbar {
        padding: 10px 0 !important;
    }

    .header .navbar .navbar-brand img {
        max-width: 130px;
    }

    .tutor-title {
        font-size: 28px;
        line-height: 36px;
    }

    .tutor-card-filter {
        position: fixed;
        z-index: 2;
        background-color: white;
        max-width: 260px;
        padding: 20px;
        overflow: auto;
        left: -100%;
        top: 0;
        height: 100%;
        transition: 0.5s all ease-in-out;
    }

    .tutor-card-filter.active {
        left: 0;
    }

    .tutor-title .mobile-setting::after {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        transition: 0.5s all ease-in-out;
    }

    .tutor-title .mobile-setting.active::after {
        opacity: 1;
        visibility: visible;
        overflow: auto;
    }

    .tutor-profile .tutor-profile-top {
        padding: 20px;
    }

    .tutor-profile-bottom>div {
        flex: 1 0 100%;
    }

    .tutor-info .nav-tabs .nav-link {
        padding-bottom: 5px;
    }

    .tutor-info .intro-box .title {
        font-size: 28px;
        line-height: 34px;
    }

    .tutor-info .intro-box .content {
        font-size: 18px;
        line-height: 34px;
    }

    .tutor-info .review-box .review-col {
        gap: 24px;
    }

    .tutor-info .review-box .review-item .name h4 {
        font-size: 16px;
        line-height: 20px;
    }

    .tutor-info .review-box .review-item .content {
        font-size: 14px;
        line-height: 18px;
    }

    .tutor-info .review-box .review-item .user-avatar {
        width: 30px;
        height: 30px;
        min-width: 30px;
    }

    .location-modal .location-search {
        padding: 16px;
    }
}

@media (max-width: 575.98px) {
    .tutor-banner-slider .slider-box .title {
        font-size: 16px;
    }

    .tutor-banner-slider .slider-box .subtitle {
        font-size: 10px;
    }

    .tutor-banner-slider .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
        width: 20px;
        height: 2px;
    }

    .tutor-card-wrapper {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }

    .tutor-title {
        font-size: 20px;
        line-height: 26px;
    }

    .tutor-title .mobile-setting {
        width: 34px;
        height: 34px;
        min-width: 34px;
    }

    .tutor-info .intro-box .content {
        font-size: 16px;
        line-height: 26px;
    }

    .tutor-info,
    .tutor-profile {
        margin-bottom: 30px;
    }

    .create-tutor-modal iframe {
        height: 220px;
    }
}