@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');

body {
    font-family: 'Nunito', sans-serif;
    overflow-x: hidden;
}

.hover-shadow:hover {
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
}

.ant-upload-list-item-name {
    text-wrap: wrap !important;
}

body {
    /* font-family: 'Circular Std Book'; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #71748d;
    background-color: #efeff6;
    -webkit-font-smoothing: antialiased;
    line-height: 1.6;
}

.mt-20 {
    margin-top: 50px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #3d405c;
    margin: 0px 0px 15px 0px;
    font-family: 'Circular Std Medium';
}

h1 {
    font-size: 34px;
}

h2 {
    font-size: 28px;
    line-height: 30px;
}

h3 {
    font-size: 20px;
}

h4 {
    font-size: 16px;
    line-height: 26px;
}

h5 {
    font-size: 15px;
    line-height: 26px;
}

h6 {
    font-size: 12px;
}

p {
    margin: 0px 0px 20px 0px;
}

p:last-child {
    margin: 0px;
}

a {
    color: #71748d;
}

a:hover {
    color: #ff407b;
    text-decoration: none;
}

a:active,
a:hover {
    outline: 0;
    text-decoration: none;
}

.btn-secondary {
    color: #fff;
    background-color: #ff407b;
    border-color: #ff407b;
}

.btn {
    font-size: 14px;
    padding: 9px 16px;
    border-radius: 2px;
}


.tab-vertical .nav.nav-tabs {
    float: left;
    display: block;
    margin-right: 0px;
    border-bottom: 0;
}

.tab-vertical .nav.nav-tabs .nav-item {
    margin-bottom: 6px;
}

.tab-vertical .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    background: #fff;
    padding: 17px 49px;
    color: #71748d;
    background-color: #dddde8;
    -webkit-border-radius: 4px 0px 0px 4px;
    -moz-border-radius: 4px 0px 0px 4px;
    border-radius: 4px 0px 0px 4px;
}

.tab-vertical .nav-tabs .nav-link.active {
    color: #5969ff;
    background-color: #fff !important;
    border-color: transparent !important;
}

.tab-vertical .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 0px !important;
}

.tab-vertical .tab-content {
    overflow: auto;
    -webkit-border-radius: 0px 4px 4px 4px;
    -moz-border-radius: 0px 4px 4px 4px;
    border-radius: 0px 4px 4px 4px;
    background: #fff;
    padding: 30px;
}

.page-loader-wrapper {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(241, 241, 241, 0.8);
}

.page-loader-wrapper p {
    margin: 0;
    color: var(--midnight-blue);
}

.page-loader-wrapper .loader {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: 100%;
    text-align: center;
}

.thanks-content .ant-result-subtitle {
    font-size: 20px;
}


.teacher-landing {
    body {
        font-family: Arial, sans-serif;
        background-color: #f5f5f5;
    }

    .filter-section {
        background: linear-gradient(to right, #b200b2, #00b2b2);
        padding-top: 100px;
        /* Adjust padding to accommodate fixed header */
    }

    .profile-image {
        background-color: #e0e0e0;
    }

    .info {
        background-color: #e0e0e0;
    }

    .card {
        border: 2px solid pink;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .card:hover {
        transform: translateY(-10px);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    }


}

.btn {
    border-radius: 30px;
    transition: all 0.3s ease;
}

.btn:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

footer {
    background: linear-gradient(to right, #b200b2, #00b2b2);
}

.auth-buttons {
    display: flex;
    gap: 10px;
    /* Adjust the spacing between the buttons */
    align-items: center;
}

.btn-primary {
    color: #fff;
    background-color: #b200b2;
    border-color: #b200b2;
}

/* Optionally, override hover and active states for consistency */
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: #b200b2;
    border-color: #b200b2;
}

@media (max-width: 767px) {
    .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-brand img {
        width: 100px !important;
        height: auto !important;
    }

    .navbar-toggler {
        padding: 8px;
        border-radius: 100rem;
        font-size: 1rem;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .teacher-landing {
        .filter-section {
            padding-top: 40px;
        }
    }

    .tab-vertical .nav.nav-tabs {
        float: unset;
    }
}